// src/components/Helpers/Modal.js
import React, { useEffect } from 'react';
import { useSwipeable } from 'react-swipeable';
import './Modal.css';

const Modal = ({ isOpen, onClose, imageSrc, title = '', onPrev, onNext }) => {
  const handlers = useSwipeable({
    onSwipedLeft: onNext,
    onSwipedRight: onPrev,
    onSwipedUp: (event) => event.event.preventDefault(),
    onSwipedDown: (event) => event.event.preventDefault(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        onClose();
      } else if (e.key === 'ArrowLeft') {
        onPrev();
      } else if (e.key === 'ArrowRight') {
        onNext();
      }
    };

    if (isOpen) {
      document.addEventListener('keydown', handleKeyDown);
      document.body.classList.add('no-scroll');
    } else {
      document.removeEventListener('keydown', handleKeyDown);
      document.body.classList.remove('no-scroll');
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.body.classList.remove('no-scroll');
    };
  }, [isOpen, onClose, onPrev, onNext]);

  if (!isOpen) return null;

  return (
    <div
      className='fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50'
      {...handlers}
    >
      <div className='relative bg-white p-4 rounded-lg modal-container'>
        <div className='modal-header'>
          <button className='close-button' onClick={onClose}>
            &times;
          </button>
          <h2 className='modal-title'>{title}</h2>
        </div>
        <div className='modal-content-wrapper'>
          <button
            className='absolute left-0 m-1 text-secondary text-2xl border-1 border-gray-300 bg-gray-100 p-2 rounded-full'
            onClick={onPrev}
          >
            &#9664;
          </button>
          <img src={imageSrc} alt='Full Screen' className='modal-image' />
          <button
            className='absolute right-0 m-1 text-secondary text-2xl border-1 border-gray-300 bg-gray-100 p-2 rounded-full'
            onClick={onNext}
          >
            &#9654;
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
