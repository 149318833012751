import React, { useState, useEffect, useRef } from 'react';
import Modal from '../Helpers/Modal';
import Swal from 'sweetalert2';
import { useBackendEndpointContext } from '../../contexts/Contexts';
import ReCAPTCHA from 'react-google-recaptcha';

const PhotoGallery = () => {
  const backendEndpoint = useBackendEndpointContext();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [photos, setPhotos] = useState([]);
  const [loading, setLoading] = useState(true);
  const fileInputRef = useRef(null);
  const recaptchaRef = useRef(null);

  useEffect(() => {
    const fetchPhotos = async () => {
      try {
        const response = await fetch(`${backendEndpoint}/api/public/gallery`);
        const data = await response.json();
        setPhotos(data);
      } catch (error) {
        console.error('Error fetching photos:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPhotos();
  }, [backendEndpoint]);

  const openModal = (index) => {
    setSelectedImage(photos[index].file_path);
    setModalTitle('Fotografie');
    setCurrentIndex(index);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImage('');
    setModalTitle('');
  };

  const showPrevImage = () => {
    const newIndex =
      currentIndex - 1 + photos.length < 0
        ? photos.length - 1
        : (currentIndex - 1 + photos.length) % photos.length;
    setSelectedImage(photos[newIndex].file_path);
    setCurrentIndex(newIndex);
  };

  const showNextImage = () => {
    const newIndex = (currentIndex + 1) % photos.length;
    setSelectedImage(photos[newIndex].file_path);
    setCurrentIndex(newIndex);
  };

  const handleFileChange = async (event) => {
    const files = event.target.files;
    setUploading(true);
    Swal.fire({
      title: 'Se încarca...',
      text: 'Fotografiile tale sunt procesate. Te rog să aștepți.',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    for (let i = 0; i < files.length; i++) {
      const formData = new FormData();
      formData.append('file_name', files[i]);

      if (!process.env.REACT_APP_BACKEND_URL) {
        const recaptchaToken = await recaptchaRef.current.executeAsync();
        formData.append('recaptchaToken', recaptchaToken);
      }

      try {
        const response = await fetch(`${backendEndpoint}/api/public/gallery`, {
          method: 'POST',
          body: formData,
        });
        if (!response.ok) {
          throw new Error('Error uploading file');
        }
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }

    setUploading(false);
    Swal.close();
    Swal.fire({
      title: 'Succes!',
      text: 'Fotografiile tale au fost încărcate cu succes. Le vom revizui în curând.',
      icon: 'success',
      showConfirmButton: false,
      timer: 4000,
    });
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  return (
    <div className='p-4 flex flex-col justify-center items-center'>
      <div className='bg-white shadow-lg rounded-lg p-6 mb-6 w-full max-w-md'>
        <label
          htmlFor='file-upload'
          className='text-lg font-semibold mb-2 block text-center'
        >
          Împărtășește fotografiile tale cu noi!
        </label>
        <input
          id='file-upload'
          type='file'
          multiple
          onChange={handleFileChange}
          className='mb-4 p-2 border border-gray-300 rounded-lg cursor-pointer focus:outline-none focus:ring-2 focus:ring-blue-500 w-full'
          disabled={uploading}
          ref={fileInputRef}
        />
        {!process.env.REACT_APP_BACKEND_URL && (
          <ReCAPTCHA
            sitekey='6LfsWyAqAAAAAL5H3VUFae1K9vPh06Xnt8S2x9-y'
            size='invisible'
            ref={recaptchaRef}
          />
        )}
      </div>
      {loading ? (
        <div className='text-center text-lg font-semibold'>
          Se încarcă fotografiile...
        </div>
      ) : (
        <div className='grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4'>
          {photos.map((photo, index) => (
            <div
              key={index}
              className='relative cursor-pointer overflow-hidden rounded-lg'
              onClick={() => openModal(index)}
              role='button'
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  openModal(index);
                }
              }}
            >
              <img
                src={photo.file_path}
                alt='Element galerie'
                className='w-32 h-32 object-cover transition-transform duration-300 transform hover:scale-110 rounded-lg'
              />
            </div>
          ))}
        </div>
      )}
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        imageSrc={selectedImage}
        title={modalTitle}
        onPrev={showPrevImage}
        onNext={showNextImage}
      />
    </div>
  );
};

export default PhotoGallery;
