// src/components/LandingPage/LandingPage.js
import React, { useState, useEffect } from 'react';
import Modal from '../Helpers/Modal';
import { useBackendEndpointContext } from '../../contexts/Contexts';

const LandingPage = () => {
  const backendEndpoint = useBackendEndpointContext();
  const [articles, setArticles] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await fetch(`${backendEndpoint}/api/public/articles`);
        const data = await response.json();
        setArticles(data);
      } catch (error) {
        console.error('Error fetching articles:', error);
      }
    };

    fetchArticles();
  }, [backendEndpoint]);

  const openModal = (index) => {
    setSelectedImage(articles[index].file_path);
    setModalTitle(articles[index].name);
    setCurrentIndex(index);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImage('');
    setModalTitle('');
  };

  const showPrevImage = () => {
    const newIndex = (currentIndex - 1 + articles.length) % articles.length;
    setSelectedImage(articles[newIndex].file_path);
    setModalTitle(articles[newIndex].name);
    setCurrentIndex(newIndex);
  };

  const showNextImage = () => {
    const newIndex = (currentIndex + 1) % articles.length;
    setSelectedImage(articles[newIndex].file_path);
    setModalTitle(articles[newIndex].name);
    setCurrentIndex(newIndex);
  };

  return (
    <div className='bg-gray-20 min-h-screen flex flex-col justify-center items-center relative'>
      <div className='timeline-line absolute left-1/2 transform -translate-x-1/2 bg-gray-300'></div>
      <div className='flex flex-col md:flex-row items-center bg-red-50 shadow-md rounded-lg p-8 space-y-8 md:space-y-0 md:space-x-8 mt-12 border-2 border-gray-200 mx-4 md:mx-16 lg:mx-32 xl:mx-48'>
        <div className='flex-1 text-center'>
          <img
            src='/groom.png'
            alt='Mire'
            className='w-48 h-48 rounded-full mx-auto mb-4 border-2 thick-dark-golden-border'
          />
          <h2 className='text-3xl font-semibold text-primary'>Augustin</h2>
          <p className='text-gray-700 text-lg-plus mt-4'>
            Augustin este o persoană pasionată, cu dragoste pentru aventură și
            noi experiențe. El aduce bucurie și râsete oriunde merge.
          </p>
        </div>
        <div className='flex-1 text-center'>
          <img
            src='/baby.png'
            alt='Bebeluș Idalia'
            className='w-36 h-36 rounded-full mx-auto mb-4 border-2 thick-dark-golden-border'
          />
          <h2 className='text-3xl font-semibold text-primary'>Idalia</h2>
          <p className='text-gray-700 text-lg-plus mt-4'>
            Idalia este bucuria vieților noastre, aducând fericire și dragoste
            în familia noastră în fiecare zi.
          </p>
        </div>
        <div className='flex-1 text-center'>
          <img
            src='/bride.png'
            alt='Mireasă'
            className='w-48 h-48 rounded-full mx-auto mb-4 border-2 thick-dark-golden-border'
          />
          <h2 className='text-3xl font-semibold text-primary'>Alexandra</h2>
          <p className='text-gray-700 text-lg-plus mt-4'>
            Alexandra este o persoană amabilă și iubitoare, cu o inimă plină de
            compasiune. Ea este dedicată să facă lumea un loc mai bun.
          </p>
        </div>
      </div>
      {articles.map((article, index) => (
        <div
          key={index}
          className={`flex flex-col md:flex-row items-center bg-red-50 shadow-md rounded-lg p-8 mt-12 border-2 border-gray-200 mx-4 md:mx-16 lg:mx-32 xl:mx-48 ${
            index % 2 === 0 ? '' : 'md:flex-row-reverse'
          }`}
        >
          <div className='flex-1'>
            <div className='relative'>
              {index % 2 === 1 ? (
                <>
                  <img
                    src='/flower.png'
                    alt='Flower'
                    className='flower-transparent  absolute -top-[57px] -right-[48px] w-48 h-48 rotate-90'
                  />
                  <img
                    src='/flower.png'
                    alt='Flower'
                    className='flower-transparent absolute -bottom-[57px] -left-[48px] w-48 h-48 transform -rotate-90'
                  />
                </>
              ) : (
                <>
                  <img
                    src='/flower.png'
                    alt='Flower'
                    className='flower-transparent  absolute -top-[48px] -left-[57px] w-48 h-48'
                  />
                  <img
                    src='/flower.png'
                    alt='Flower'
                    className='flower-transparent  absolute -bottom-[48px] -right-[57px] w-48 h-48 transform rotate-180'
                  />
                </>
              )}
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
              <img
                src={article.file_path}
                alt={article.name}
                className='w-full h-auto rounded-lg mb-4 cursor-pointer dark-golden-border'
                onClick={() => openModal(index)}
              />
            </div>
          </div>
          <div className='flex-1 text-center md:text-left ml-4'>
            <h2 className='text-4xl font-semibold text-primary'>
              {article.name}
            </h2>
            <p className='text-lg text-gray-700 text-lg-plus mt-4'>
              {article.content}
            </p>
          </div>
        </div>
      ))}
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        imageSrc={selectedImage}
        title={modalTitle}
        onPrev={showPrevImage}
        onNext={showNextImage}
      />
    </div>
  );
};

export default LandingPage;
