import React, { useRef, useState } from 'react';
import Swal from 'sweetalert2';
import { useBackendEndpointContext } from '../../contexts/Contexts';
import ReCAPTCHA from 'react-google-recaptcha';

const RSVPForm = () => {
  const backendEndpoint = useBackendEndpointContext();
  const recaptchaRef = useRef(null);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    attending: null,
    guests: 1,
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleAttendingChange = (attending) => {
    setFormData({
      ...formData,
      attending,
    });
  };

  const validate = () => {
    const newErrors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!formData.name) newErrors.name = 'Numele este obligatoriu';
    if (formData.name.length < 3)
      newErrors.name = 'Numele trebuie să aibă cel puțin 3 caractere';
    if (formData.email.length > 0 && !emailRegex.test(formData.email))
      newErrors.email = 'Emailul nu este valid';
    if (!formData.phone)
      newErrors.phone = 'Numărul de telefon este obligatoriu';
    if (formData.attending === null)
      newErrors.attending = 'Vă rugăm să selectați Da sau Nu';
    if (formData.guests < 1)
      newErrors.guests = 'Este necesar cel puțin un invitat';

    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      try {
        Swal.fire({
          title: 'Se încarca...',
          text: 'Incarcam confirmarea RSVP-ului dvs.',
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        let recaptchaToken = null;
        if (!process.env.REACT_APP_BACKEND_URL) {
          recaptchaToken = await recaptchaRef.current.executeAsync();
        }
        const response = await fetch(`${backendEndpoint}/api/public/rsvp`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ ...formData, recaptchaToken }),
        });

        if (!response.ok) {
          throw new Error('Eroare la trimiterea RSVP');
        }

        Swal.fire({
          title: 'Succes!',
          text: 'RSVP-ul dvs. a fost trimis cu succes.',
          icon: 'success',
          showConfirmButton: false,
          timer: 4000,
        });

        setFormData({
          name: '',
          email: '',
          phone: '',
          attending: null,
          guests: 1,
        });
        setErrors({});
      } catch (error) {
        console.error('Eroare la trimiterea RSVP:', error);
        Swal.fire({
          title: 'Eroare!',
          text: 'A apărut o eroare la trimiterea RSVP-ului dvs. Vă rugăm să încercați din nou mai târziu.',
          icon: 'error',
          showConfirmButton: true,
        });
      }
    }
  };

  return (
    <div className='bg-gray-20 min-h-screen flex flex-col justify-center items-center relative'>
      <div className='container mx-auto text-center'>
        <form
          onSubmit={handleSubmit}
          className='bg-gray-100 shadow-md rounded-lg p-8 max-w-lg mx-auto'
        >
          <div className='form-control mb-4'>
            <label
              htmlFor='name'
              className='block text-left text-gray-700 text-lg-plus font-bold mb-2'
            >
              Nume
            </label>
            <input
              type='text'
              id='name'
              name='name'
              value={formData.name}
              onChange={handleChange}
              className='input input-bordered w-full p-2 border border-gray-300 rounded-md'
            />
            {errors.name && (
              <p className='text-red-500 text-left'>{errors.name}</p>
            )}
          </div>
          <div className='form-control mb-4'>
            <label
              htmlFor='email'
              className='block text-left text-gray-700 text-lg-plus font-bold mb-2'
            >
              Email
            </label>
            <input
              type='email'
              id='email'
              name='email'
              value={formData.email}
              onChange={handleChange}
              className='input input-bordered w-full p-2 border border-gray-300 rounded-md'
            />
            {errors.email && (
              <p className='text-red-500 text-left'>{errors.email}</p>
            )}
          </div>
          <div className='form-control mb-4'>
            <label
              htmlFor='phone'
              className='block text-left text-gray-700 text-lg-plus font-bold mb-2'
            >
              Număr de telefon
            </label>
            <input
              type='text'
              id='phone'
              name='phone'
              value={formData.phone}
              onChange={handleChange}
              className='input input-bordered w-full p-2 border border-gray-300 rounded-md'
            />
            {errors.phone && (
              <p className='text-red-500 text-left'>{errors.phone}</p>
            )}
          </div>
          <div className='form-control mb-4'>
            <label
              htmlFor='attending'
              className='block text-left text-gray-700 text-lg-plus font-bold mb-2'
            >
              Veți participa?
            </label>
            <div className='flex'>
              <button
                type='button'
                onClick={() => handleAttendingChange(true)}
                className={`px-4 py-2 mx-2 rounded-md ${formData.attending === true ? 'bg-primary text-white' : 'bg-gray-200 text-gray-700 text-lg-plus'}`}
              >
                Da
              </button>
              <button
                type='button'
                onClick={() => handleAttendingChange(false)}
                className={`px-4 py-2 mx-2 rounded-md ${formData.attending === false ? 'bg-primary text-white' : 'bg-gray-200 text-gray-700 text-lg-plus'}`}
              >
                Nu
              </button>
            </div>
            {errors.attending && (
              <p className='text-red-500 text-left'>{errors.attending}</p>
            )}
          </div>
          <div className='form-control mb-4'>
            <label
              htmlFor='guests'
              className='block text-left text-gray-700 text-lg-plus font-bold mb-2'
            >
              Număr de invitați
            </label>
            <input
              type='number'
              id='guests'
              name='guests'
              value={formData.guests}
              onChange={handleChange}
              className='input input-bordered w-full p-2 border border-gray-300 rounded-md'
            />
            {errors.guests && (
              <p className='text-red-500 text-left'>{errors.guests}</p>
            )}
          </div>
          <button
            type='submit'
            className='w-full p-2 mt-4 bg-primary text-white rounded-md'
          >
            Trimite
          </button>
          {!process.env.REACT_APP_BACKEND_URL && (
            <ReCAPTCHA
              sitekey='6LfsWyAqAAAAAL5H3VUFae1K9vPh06Xnt8S2x9-y'
              size='invisible'
              ref={recaptchaRef}
            />
          )}
        </form>
      </div>
    </div>
  );
};

export default RSVPForm;
