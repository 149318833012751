import React from 'react';
import PropTypes from 'prop-types';
import { BackendEndpointProvider } from './Contexts';

function AppContextProvider({ children, runtime }) {
  return (
    <BackendEndpointProvider value={runtime.backendEndpoint}>
      {children}
    </BackendEndpointProvider>
  );
}

AppContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  runtime: PropTypes.object.isRequired,
};

export default AppContextProvider;
