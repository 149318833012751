import React from 'react';

const WeddingPresentation = () => {
  return (
    <div className='bg-gray-20 min-h-screen p-8 flex flex-col justify-center items-center'>
      <div className='container mx-auto text-center mt-4'>
        <div className='bg-red-50 border-gray-200 border-2 shadow-md rounded-lg p-8 mb-8 flex flex-col md:flex-row items-center'>
          <div className='w-full md:w-1/3 mb-4 md:mb-0'>
            <img
              src='/invitatie.png'
              alt='Bine ați venit'
              className='w-full h-full object-cover rounded-lg'
            />
          </div>
          <div className='w-full md:w-2/3 md:pl-8'>
            <h2 className='text-3xl font-semibold text-primary'>
              Bine ați venit
            </h2>
            <p className='text-gray-700 text-lg-plus mt-4'>
              Suntem încântați să vă invităm la nunta noastră. Această zi
              specială marchează începutul unui nou capitol în viețile noastre
              și suntem bucuroși să o împărtășim cu familia și prietenii noștri.
              Prezența voastră înseamnă foarte mult pentru noi și așteptăm cu
              nerăbdare să sărbătorim această ocazie fericită împreună.
            </p>
          </div>
        </div>

        <div className='bg-red-50 border-gray-200 border-2 shadow-md rounded-lg p-8 mb-8 flex flex-col md:flex-row items-center'>
          <div className='w-full md:w-2/3 mb-4 md:mb-0 md:pr-8'>
            <div className='relative  flex justify-center items-center'>
              <img
                src='/wedding-border-inline.png'
                alt='Flower'
                className='flower-transparent w-60 absolute xl:-top-20 xl:left-20 lg:-top-20 lg:left-0 md:-top-20 md:-left-20 sm:-top-[75px] sm:-left-20 -top-[75px] -left-[110px]'
              />
              <div>
                <h2 className='text-3xl font-semibold text-primary'>
                  Botezul micuței noastre Idalia Maria
                </h2>
                <p className='text-gray-700 text-lg-plus mt-4'>
                  Data: 2 Noiembrie 2024, ora 12:00.
                </p>
                <p className='text-gray-700 text-lg-plus'>
                  Locație: Biserica Sfântul Nifon, Calea București, nr. 19,
                  Târgoviște
                </p>
                <p className='text-gray-700 text-lg-plus mt-4'>
                  Vă așteptăm la botez!
                </p>
              </div>
            </div>
          </div>
          <div className='w-full md:w-1/3'>
            <iframe
              src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3057.024325169495!2d25.478010776321135!3d44.91795926971869!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40b2f67faef7297f%3A0xe54a34dce11d50ec!2sChurch%20of%20St.%20Niphon!5e1!3m2!1sen!2sro!4v1722763008749!5m2!1sen!2sro'
              className='w-full h-64 md:h-80 rounded-lg border-2 dark-golden-border'
              allowFullScreen=''
              loading='lazy'
              referrerPolicy='no-referrer-when-downgrade'
              title='Botezul micuței noastre Idalia Maria'
            ></iframe>
          </div>
        </div>

        <div className='bg-red-50 border-gray-200 border-2 shadow-md rounded-lg p-8 mb-8 flex flex-col md:flex-row items-center'>
          <div className='w-full md:w-1/3 mb-4 md:mb-0'>
            <iframe
              src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3057.024325169495!2d25.478010776321135!3d44.91795926971869!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40b2f67faef7297f%3A0xe54a34dce11d50ec!2sChurch%20of%20St.%20Niphon!5e1!3m2!1sen!2sro!4v1722763008749!5m2!1sen!2sro'
              className='w-full h-64 md:h-80 rounded-lg border-2 dark-golden-border'
              allowFullScreen=''
              loading='lazy'
              referrerPolicy='no-referrer-when-downgrade'
              title='Cununia Religioasă la Biserica Sfântul Nifon, Calea București 19, Târgoviște'
            ></iframe>
          </div>
          <div className='w-full md:w-2/3 md:pl-8'>
            <h2 className='text-3xl font-semibold text-primary'>
              Cununia Religioasă
            </h2>
            <p className='text-gray-700 text-lg-plus mt-4'>
              Data: 2 Noiembrie 2024, ora 17:00.
            </p>
            <p className='text-gray-700 text-lg-plus'>
              Locație: Biserica Sfântul Nifon, Calea București, nr. 19,
              Târgoviște
            </p>
            <p className='text-gray-700 text-lg-plus mt-4'>
              Suntem încântați să vă invităm la nunta noastră. Această zi
              specială marchează începutul unui nou capitol în viețile noastre
              și suntem bucuroși să o împărtășim cu familia și prietenii noștri
              apropiați. Prezența voastră înseamnă foarte mult pentru noi și
              așteptăm cu nerăbdare să sărbătorim această ocazie fericită
              împreună.
            </p>
          </div>
        </div>

        <div className='bg-red-50 border-gray-200 border-2 shadow-md rounded-lg p-8 mb-8 flex flex-col md:flex-row items-center'>
          <div className='w-full md:w-2/3 mb-4 md:mb-0 md:pr-8'>
            <div className='relative flex justify-center items-center'>
              <img
                src='/wedding-border-inline.png'
                alt='Flower'
                className='flower-transparent w-60 absolute xl:-top-20 xl:left-20 lg:-top-20 lg:left-0 md:-top-20 md:-left-20 sm:-top-[75px] sm:-left-20 -top-[75px] -left-[110px]'
              />
              <div className='w-2/3'>
                <h2 className='text-3xl font-semibold text-primary'>
                  Petrecerea
                </h2>
                <p className='text-gray-700 text-lg-plus mt-4'>
                  Data: 2 Noiembrie 2024, ora 19:00.
                </p>
                <p className='text-gray-700 text-lg-plus'>
                  Locație: Restaurantul hotelului Chițulescu, B-dul Eroilor, nr.
                  24-26, Târgoviște
                </p>
                <p className='text-gray-700 text-lg-plus mt-4'>
                  Alăturați-vă nouă pentru o sărbătoare plină de muzică, dans și
                  bucurie.
                </p>
              </div>
            </div>
          </div>
          <div className='w-full md:w-1/3'>
            <iframe
              src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3056.086645114539!2d25.437690076322045!3d44.93558126855356!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40b2f7004b02c657%3A0xa23bf38d80ee9602!2sHotel%20Chi%C8%9Bulescu!5e1!3m2!1sen!2sro!4v1722762801066!5m2!1sen!2sro'
              className='w-full h-64 md:h-80 rounded-lg border-2 dark-golden-border'
              allowFullScreen=''
              loading='lazy'
              referrerPolicy='no-referrer-when-downgrade'
              title='Petrecerea la Restaurantul hotelului Chițulescu, B-dul Eroilor, nr. 24-26, Târgoviște'
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WeddingPresentation;
