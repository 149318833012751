import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

const createContextFactory = (defaultValue) => {
  const Context = createContext(defaultValue);

  function Provider({ children, value }) {
    return <Context.Provider value={value}>{children}</Context.Provider>;
  }

  Provider.propTypes = {
    children: PropTypes.node.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    value: PropTypes.any.isRequired,
  };

  const useCustomContext = () => useContext(Context);

  return [Provider, useCustomContext];
};

export default createContextFactory;
