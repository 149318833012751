import React, { useState, useEffect, useCallback } from 'react';
import { useBackendEndpointContext } from '../../contexts/Contexts';
import { format } from 'date-fns';

const RSVPs = () => {
  const backendEndpoint = useBackendEndpointContext();
  const [rsvps, setRsvps] = useState([]);
  const [filter, setFilter] = useState('all');

  const fetchRsvps = useCallback(async () => {
    const response = await fetch(`${backendEndpoint}/api/admin/rsvps`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    });
    const data = await response.json();
    setRsvps(data);
  }, [backendEndpoint]);

  useEffect(() => {
    fetchRsvps();
  }, [fetchRsvps]);

  const filteredRsvps = rsvps.filter((rsvp) => {
    if (filter === 'confirmed') return rsvp.attending === 1;
    if (filter === 'rejected') return rsvp.attending === 0;
    return true;
  });

  return (
    <div className='p-4 bg-white rounded shadow-md'>
      <h2 className='text-2xl font-bold mb-4'>RSVPs</h2>
      <div className='mb-4'>
        <button
          onClick={() => setFilter('all')}
          className={`px-4 py-2 rounded ${filter === 'all' ? 'bg-blue-500 text-white' : 'bg-white text-blue-500'}`}
        >
          All
        </button>
        <button
          onClick={() => setFilter('confirmed')}
          className={`px-4 py-2 rounded ${filter === 'confirmed' ? 'bg-blue-500 text-white' : 'bg-white text-blue-500'}`}
        >
          Confirmed
        </button>
        <button
          onClick={() => setFilter('rejected')}
          className={`px-4 py-2 rounded ${filter === 'rejected' ? 'bg-blue-500 text-white' : 'bg-white text-blue-500'}`}
        >
          Rejected
        </button>
      </div>
      <p className='mb-4'>Showing {filteredRsvps.length} RSVP(s)</p>
      <ul className='space-y-4'>
        {filteredRsvps.map((rsvp) => (
          <li
            key={rsvp.id}
            className='p-4 bg-gray-100 rounded shadow flex flex-col'
          >
            <span className='font-bold'>{rsvp.name}</span>
            <span>{rsvp.email}</span>
            <span>{rsvp.phone}</span>
            <span>Attending: {rsvp.attending === 1 ? 'Yes 🥳' : 'No 😢'}</span>
            <span>Guests: {rsvp.guests}</span>
            <span>
              Confirmed at:{' '}
              {format(new Date(rsvp.created_at), 'yyyy-MM-dd HH:mm:ss')}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default RSVPs;
