import React, { useState, useEffect, useCallback } from 'react';
import { useBackendEndpointContext } from '../../contexts/Contexts';
import Swal from 'sweetalert2';

const Articles = () => {
  const backendEndpoint = useBackendEndpointContext();
  const [articles, setArticles] = useState([]);
  const [newArticle, setNewArticle] = useState({
    name: '',
    content: '',
    file_name: null,
  });
  const [editingArticle, setEditingArticle] = useState(null);
  const [errors, setErrors] = useState({});

  const fetchArticles = useCallback(async () => {
    try {
      const response = await fetch(`${backendEndpoint}/api/admin/articles`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      const data = await response.json();
      setArticles(data);
    } catch (error) {
      console.error('Error fetching articles:', error);
    }
  }, [backendEndpoint]);

  useEffect(() => {
    fetchArticles();
  }, [fetchArticles]);

  const validateArticle = (article, isUpdate = false) => {
    const newErrors = {};
    if (!article.name) newErrors.name = 'Name is required';
    if (!article.content) newErrors.content = 'Content is required';
    if (!isUpdate && !article.file_name)
      newErrors.file_name = 'File is required';
    return newErrors;
  };

  const handleAddArticle = async () => {
    const validationErrors = validateArticle(newArticle);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const formData = new FormData();
    formData.append('name', newArticle.name);
    formData.append('content', newArticle.content);
    if (newArticle.file_name) {
      formData.append('file_name', newArticle.file_name);
    }

    const response = await fetch(`${backendEndpoint}/api/admin/articles`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: formData,
    });
    if (response.ok) {
      fetchArticles();
      setNewArticle({ name: '', content: '', file_name: null });
      setErrors({});
    } else {
      Swal.fire('Error', 'Failed to add article', 'error');
    }
  };

  const handleUpdateArticle = async () => {
    const validationErrors = validateArticle(editingArticle, true);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const formData = new FormData();
    formData.append('name', editingArticle.name);
    formData.append('content', editingArticle.content);
    if (editingArticle.file_name) {
      formData.append('file_name', editingArticle.file_name);
    }

    const response = await fetch(
      `${backendEndpoint}/api/admin/articles/${editingArticle.id}`,
      {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: formData,
      }
    );
    if (response.ok) {
      fetchArticles();
      setEditingArticle(null);
      setErrors({});
    } else {
      Swal.fire('Error', 'Failed to update article', 'error');
    }
  };

  const confirmDeletion = async () => {
    return Swal.fire({
      title: 'Are you sure you want to delete this article?',
      text: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    });
  };

  const handleDeleteArticle = async (id) => {
    const result = await confirmDeletion();
    if (result.isConfirmed) {
      const response = await fetch(
        `${backendEndpoint}/api/admin/articles/${id}`,
        {
          method: 'DELETE',
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        }
      );
      if (response.ok) {
        fetchArticles();
      } else {
        Swal.fire('Error', 'Failed to delete article', 'error');
      }
    }
  };

  return (
    <div className='p-4 bg-white rounded shadow-md'>
      <h2 className='text-2xl font-bold mb-4'>Articles</h2>
      <div className='mb-4'>
        <input
          type='text'
          value={newArticle.name}
          onChange={(e) =>
            setNewArticle({ ...newArticle, name: e.target.value })
          }
          placeholder='Name'
          className='w-full px-3 py-2 mb-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500'
        />
        {errors.name && <p className='text-red-500'>{errors.name}</p>}
        <textarea
          value={newArticle.content}
          onChange={(e) =>
            setNewArticle({ ...newArticle, content: e.target.value })
          }
          placeholder='Content'
          className='w-full px-3 py-2 mb-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500'
        ></textarea>
        {errors.content && <p className='text-red-500'>{errors.content}</p>}
        <input
          type='file'
          onChange={(e) =>
            setNewArticle({ ...newArticle, file_name: e.target.files[0] })
          }
          className='w-full px-3 py-2 mb-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500'
        />
        {errors.file_name && <p className='text-red-500'>{errors.file_name}</p>}
        <button
          onClick={handleAddArticle}
          className='w-full bg-blue-500 text-white py-2 rounded hover:bg-blue-600 transition duration-200'
        >
          Add Article
        </button>
      </div>
      <p className='mb-4'>Showing {articles.length} article(s)</p>
      <ul className='space-y-4'>
        {articles.map((article) => (
          <li key={article.id} className='p-4 bg-gray-100 rounded shadow flex'>
            {article.file_name && (
              <img
                src={article.file_path}
                alt={article.file_name}
                className='w-24 h-24 object-cover rounded mr-4'
              />
            )}
            <div className='flex-1'>
              {editingArticle && editingArticle.id === article.id ? (
                <div>
                  <input
                    type='text'
                    value={editingArticle.name}
                    onChange={(e) =>
                      setEditingArticle({
                        ...editingArticle,
                        name: e.target.value,
                      })
                    }
                    placeholder='Name'
                    className='w-full px-3 py-2 mb-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500'
                  />
                  {errors.name && <p className='text-red-500'>{errors.name}</p>}
                  <textarea
                    value={editingArticle.content}
                    onChange={(e) =>
                      setEditingArticle({
                        ...editingArticle,
                        content: e.target.value,
                      })
                    }
                    placeholder='Content'
                    className='w-full px-3 py-2 mb-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500'
                  ></textarea>
                  {errors.content && (
                    <p className='text-red-500'>{errors.content}</p>
                  )}
                  <input
                    type='file'
                    onChange={(e) =>
                      setEditingArticle({
                        ...editingArticle,
                        file_name: e.target.files[0],
                      })
                    }
                    className='w-full px-3 py-2 mb-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500'
                  />
                  {errors.file_name && (
                    <p className='text-red-500'>{errors.file_name}</p>
                  )}
                  <button
                    onClick={handleUpdateArticle}
                    className='w-full bg-green-500 text-white py-2 rounded hover:bg-green-600 transition duration-200'
                  >
                    Update Article
                  </button>
                  <button
                    onClick={() => setEditingArticle(null)}
                    className='w-full bg-gray-500 text-white py-2 rounded hover:bg-gray-600 transition duration-200 mt-2'
                  >
                    Cancel
                  </button>
                </div>
              ) : (
                <div>
                  <h3 className='text-xl font-bold'>{article.name}</h3>
                  <p className='mt-2'>{article.content}</p>
                  <button
                    onClick={() =>
                      setEditingArticle({
                        ...article,
                        file_name: null,
                        file_path: null,
                      })
                    }
                    className='bg-yellow-500 text-white px-3 py-1 rounded hover:bg-yellow-600 transition duration-200 mt-2'
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => handleDeleteArticle(article.id)}
                    className='bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600 transition duration-200 mt-2 ml-2'
                  >
                    Delete
                  </button>
                </div>
              )}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Articles;
