import React from 'react';

const Header = () => {
  return (
    <>
      <div className='top-background w-full'></div>
      <div className='container mx-auto text-center mt-[-150px] z-10 mb-5'>
        <h1 className='text-5xl font-bold text-primary mb-4'>
          Bine ați venit la nunta noastră!
        </h1>
        <p className='text-lg text-gray-700 text-lg-plus'>
          Alăturați-vă nouă pentru a ne sărbători dragostea!
        </p>
      </div>
    </>
  );
};

export default Header;
