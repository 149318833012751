// src/components/Admin/Dashboard.js
import React, { useState } from 'react';
import Articles from './Articles';
import Gallery from './Gallery';
import RSVPs from './RSVPs';

const Dashboard = ({ handleLogout }) => {
  const [activeTab, setActiveTab] = useState('articles');

  const renderContent = () => {
    switch (activeTab) {
      case 'articles':
        return <Articles />;
      case 'gallery':
        return <Gallery />;
      case 'rsvps':
        return <RSVPs />;
      default:
        return null;
    }
  };

  return (
    <div className='min-h-screen bg-gray-100 p-4'>
      <nav className='flex justify-center space-x-4 mb-4'>
        <button
          onClick={() => setActiveTab('articles')}
          className={`px-4 py-2 rounded ${
            activeTab === 'articles'
              ? 'bg-blue-500 text-white'
              : 'bg-white text-blue-500'
          }`}
        >
          Articles
        </button>
        <button
          onClick={() => setActiveTab('gallery')}
          className={`px-4 py-2 rounded ${
            activeTab === 'gallery'
              ? 'bg-blue-500 text-white'
              : 'bg-white text-blue-500'
          }`}
        >
          Gallery
        </button>
        <button
          onClick={() => setActiveTab('rsvps')}
          className={`px-4 py-2 rounded ${
            activeTab === 'rsvps'
              ? 'bg-blue-500 text-white'
              : 'bg-white text-blue-500'
          }`}
        >
          RSVPs
        </button>
        <button
          onClick={handleLogout}
          className='px-4 py-2 rounded bg-red-500 text-white'
        >
          Logout
        </button>
      </nav>
      <div className='bg-white p-6 rounded shadow-md'>{renderContent()}</div>
    </div>
  );
};

export default Dashboard;
