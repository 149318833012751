import React from 'react';

const NavBar = ({ currentSection, setCurrentSection }) => {
  return (
    <nav className='fixed top-4 left-1/2 transform -translate-x-1/2 bg-white shadow-md rounded-full p-4 z-50'>
      <ul className='flex space-x-2 md:space-x-4'>
        <li>
          <button
            className={`px-2 py-1 md:px-4 md:py-2 rounded-full ${currentSection === 'acasa' ? 'bg-primary text-white' : 'text-gray-700 text-lg-plus'}`}
            onClick={() => setCurrentSection('acasa')}
          >
            <span className='hidden md:inline whitespace-nowrap'>
              Noi trei și familia noastră
            </span>
            <span className='md:hidden whitespace-nowrap'>Noi</span>
          </button>
        </li>
        <li>
          <button
            className={`px-2 py-1 md:px-4 md:py-2 rounded-full ${currentSection === 'nunta' ? 'bg-primary text-white' : 'text-gray-700 text-lg-plus'}`}
            onClick={() => setCurrentSection('nunta')}
          >
            <span className='hidden md:inline whitespace-nowrap'>
              Nuntă & Botez
            </span>
            <span className='md:hidden whitespace-nowrap'>Nuntă</span>
          </button>
        </li>
        {/*<li>*/}
        {/*  <button*/}
        {/*    className={`px-2 py-1 md:px-4 md:py-2 rounded-full ${currentSection === 'galerie' ? 'bg-primary text-white' : 'text-gray-700 text-lg-plus'}`}*/}
        {/*    onClick={() => setCurrentSection('galerie')}*/}
        {/*  >*/}
        {/*    <span className='hidden md:inline whitespace-nowrap'>*/}
        {/*      Galerie Foto*/}
        {/*    </span>*/}
        {/*    <span className='md:hidden whitespace-nowrap'>Galerie</span>*/}
        {/*  </button>*/}
        {/*</li>*/}
        <li>
          <button
            className={`px-2 py-1 md:px-4 md:py-2 rounded-full ${currentSection === 'rsvp' ? 'bg-primary text-white' : 'text-gray-700 text-lg-plus'}`}
            onClick={() => setCurrentSection('rsvp')}
          >
            <span className='whitespace-nowrap'>Confirmă prezența</span>
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default NavBar;
