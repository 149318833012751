import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';
import NavBar from './components/Commons/NavBar';
import Header from './components/Commons/Header';
import LandingPage from './components/LandingPage/LandingPage';
import WeddingPresentation from './components/WeddingPresentation/WeddingPresentation';
import RSVPForm from './components/RSVP/RSVPForm';
import Footer from './components/Commons/Footer';
import PhotoGallery from './components/PhotoGallery/PhotoGallery';
import Login from './components/Admin/Login';
import Dashboard from './components/Admin/Dashboard';
import './App.css';
import AppContextProvider from './contexts/AppContextProvider';

const runtime = {
  backendEndpoint:
    process.env.REACT_APP_BACKEND_URL ?? 'https://api.noi-trei.com',
};

function App() {
  const [currentSection, setCurrentSection] = useState('acasa');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleLogin = () => {
    setIsAuthenticated(true);
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    localStorage.removeItem('token');
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setLoading(true);
      fetch(`${runtime.backendEndpoint}/api/auth/check-auth`, {
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((response) => {
          console.log(response.status);
          if (response.status <= 204 && response.status >= 200) {
            setIsAuthenticated(true);
          } else {
            setIsAuthenticated(false);
            //remove token from local storage
            localStorage.removeItem('token');
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);

          setIsAuthenticated(false);
        });
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    let title;
    switch (currentSection) {
      case 'acasa':
        title = 'Noi trei și familia noastră';
        break;
      case 'nunta':
        title = 'Evenimentul de Nuntă și Botez';
        break;
      case 'rsvp':
        title = 'RSVP';
        break;
      case 'galerie':
        title = 'Galerie Foto';
        break;
      default:
        title = 'Noi trei';
    }
    document.title = `${title} - Augustin & Alexandra & Idalia`;
  }, [currentSection]);

  return (
    <AppContextProvider runtime={runtime}>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <Router>
          <Routes>
            <Route
              path='/login'
              element={
                <div className='App'>
                  {isAuthenticated ? (
                    <Navigate replace to='/admin' />
                  ) : (
                    <Login onLogin={handleLogin} />
                  )}
                </div>
              }
            />
            <Route
              path='/admin'
              element={
                isAuthenticated ? (
                  <Dashboard handleLogout={handleLogout} />
                ) : (
                  <Navigate to='/login' />
                )
              }
            />
            <Route
              path='/'
              element={
                <div className='App main'>
                  <NavBar
                    currentSection={currentSection}
                    setCurrentSection={setCurrentSection}
                  />
                  <Header />
                  {currentSection === 'acasa' && <LandingPage />}
                  {currentSection === 'nunta' && <WeddingPresentation />}
                  {currentSection === 'galerie' && <PhotoGallery />}
                  {currentSection === 'rsvp' && <RSVPForm />}
                  <Footer />
                </div>
              }
            />
          </Routes>
        </Router>
      )}
    </AppContextProvider>
  );
}

export default App;
