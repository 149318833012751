import React, { useEffect } from 'react';

const Footer = () => {
  const updateFooterHeight = () => {
    const footer = document.querySelector('footer');
    if (footer) {
      const footerHeight = footer.offsetHeight;
      document.documentElement.style.setProperty(
        '--footer-height',
        `${footerHeight + 20}px`
      );
    }
  };

  useEffect(() => {
    updateFooterHeight();
    window.addEventListener('resize', updateFooterHeight);
    return () => {
      window.removeEventListener('resize', updateFooterHeight);
    };
  }, []);

  return (
    <footer className='bg-gray-200 text-center py-4 border-t border-gray-200 fixed bottom-0 w-full'>
      <p className='text-primary'>
        Așteptăm să ne confirmați prezența până in data de 19 Octombrie 2024!
      </p>
    </footer>
  );
};

export default Footer;
