import React, { useState, useEffect, useCallback } from 'react';
import { useBackendEndpointContext } from '../../contexts/Contexts';
import Swal from 'sweetalert2';

const Gallery = () => {
  const backendEndpoint = useBackendEndpointContext();
  const [photos, setPhotos] = useState([]);

  const fetchPhotos = useCallback(async () => {
    const response = await fetch(`${backendEndpoint}/api/admin/gallery`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    });
    const data = await response.json();
    setPhotos(data);
  }, [backendEndpoint]);

  useEffect(() => {
    fetchPhotos();
  }, [fetchPhotos]);

  const handleApprovePhoto = async (id) => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to approve this photo?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, approve it!',
    });

    if (result.isConfirmed) {
      const response = await fetch(
        `${backendEndpoint}/api/admin/gallery/${id}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          body: JSON.stringify({ approved: true }),
        }
      );
      if (response.ok) {
        fetchPhotos();
      } else {
        Swal.fire('Error', 'Failed to approve photo', 'error');
      }
    }
  };

  const handleDeletePhoto = async (id) => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to delete this photo?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    });

    if (result.isConfirmed) {
      const response = await fetch(
        `${backendEndpoint}/api/admin/gallery/${id}`,
        {
          method: 'DELETE',
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        }
      );
      if (response.ok) {
        fetchPhotos();
      } else {
        Swal.fire('Error', 'Failed to delete photo', 'error');
      }
    }
  };

  return (
    <div className='p-4 bg-white rounded shadow-md'>
      <h2 className='text-2xl font-bold mb-4'>Gallery</h2>
      <p className='mb-4'>Showing {photos.length} photo(s)</p>
      <ul className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4'>
        {photos.map((photo) => (
          <li key={photo.id} className='bg-gray-100 p-4 rounded shadow'>
            <img
              src={photo.file_path}
              alt={photo.file_name}
              className='w-full h-auto object-cover rounded mb-4'
            />
            <div className='flex flex-row-reverse'>
              {photo.approved === 0 && (
                <button
                  onClick={() => handleApprovePhoto(photo.id)}
                  className='bg-green-500 text-white m-2 p-3 py-1 rounded hover:bg-green-600 transition duration-200'
                >
                  Approve
                </button>
              )}
              <button
                onClick={() => handleDeletePhoto(photo.id)}
                className='bg-red-500 text-white m-2 p-3 py-1 rounded hover:bg-red-600 transition duration-200'
              >
                Delete
              </button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Gallery;
